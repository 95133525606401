html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

* {
  box-sizing: border-box;
}

.fileDrop {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
}

.mural {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
}

.btn {
  background: black;
  padding: 12px 20px;
  color: white;
  flex: 1 0 auto;
  border-radius: 999px;
  transform: all 400ms ease;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-secondary {
  background: white;
  color: black;
  border: 2px solid rgba(0,0,0,0.1)
}

.muralDrop {
  transition: all 200ms ease-in-out;
}

.dropSplash:hover .muralDrop {
  transform: scale(1.2);
}